<template>
  <div class="card WidgetDetail">
    <div class="row">
      <div class="col-lg-4">
        <div class="input-wrap">
          <label for="title">{{ t('title') }}
            <span v-if="isHeaderTitleRequired" class="required-star">*</span>
          </label>
          <input
            class="form-control"
            v-model="state.header_title"
            type="text"
            :disabled="!state.header_visible"
            name="title"
            id="title"
          />
          <div v-if="isHeaderTitleInvalid" class="error-msg">
            {{ t('required.title') }}
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input-wrap">
          <label for="subTitle">{{ t('rightTitle') }}
            <span v-if="isHeaderRightTitleRequired" class="required-star">*</span>
          </label>
          <input
            class="form-control"
            v-model="state.header_right_title"
            type="text"
            :disabled="!state.header_visible || !state.header_right_visible"
            name="subTitle"
            id="subTitle"
          />
          <div v-if="isHeaderRightTitleInvalid" class="error-msg">
            {{ t('required.rightTitle') }}
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <TagsInput
          v-model="state.header_right_shopify_collection_id"
          :items="collections"
          :label="t('headerRightShowcaseCollections')"
          :has-error="isHeaderRightShopifyCollectionIdInvalid"
          :disabled="!state.header_visible || !state.header_right_visible"
          :required="isHeaderRightShopifyCollectionIdRequired"
        />
      </div>
      <div class="col-lg-4" style="display: flex">
        <div class="input-wrap">
          <MySwitch
            v-model="state.header_visible"
            :label="t('headerIsVisible')"
          />
        </div>
      </div>
      <div class="col-lg-4" style="display: flex">
        <div class="input-wrap">
          <MySwitch
            :disabled="!state.header_visible"
            v-model="state.header_right_visible"
            :label="t('rightHeaderIsVisible')"
          />
        </div>
      </div>

      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6">
            <div class="input-wrap">
              <div>
                <label class="mb-2">{{ t('type') }}</label>
              </div>
              <a-radio-group v-model:value="state.source_type">
                <a-radio value="SHOPIFY_COLLECTION">{{
                    t('collection')
                  }}
                </a-radio>
                <a-radio value="SHOPIFY_PRODUCTS">{{ t('product') }}</a-radio>
              </a-radio-group>
            </div>
          </div>

          <div
            class="col-lg-6"
            v-if="state.source_type === 'SHOPIFY_COLLECTION'"
          >
            <div class="input-wrap">
              <TagsInput
                v-model="state.shopify_collection_id"
                :items="collections"
                :label="t('showcaseCollections')"
                :required="isShopifyCollectionIdRequired"

              />
            </div>
          </div>

          <div class="col-lg-6" v-if="state.source_type === 'SHOPIFY_PRODUCTS'">
            <div class="input-wrap">
              <label>{{ t('products') }}
                <span v-if="isShopifyProductIdsRequired" class="required-star">*</span>
              </label>
              <a-select
                mode="multiple"
                :placeholder="t('products')"
                v-model:value="state.shopify_product_ids"
              >
                <a-select-option
                  :value="item.id"
                  v-for="item in products"
                  :key="item.id"
                >
                  <img
                    style="width: 100px; height: 100px; object-fit: contain"
                    :src="item.image_url"
                  />
                  {{ item.title }}
                </a-select-option>
              </a-select>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="input-wrap">
              <label>{{ t('itemCount') }}</label>
              <a-radio-group v-model:value="state.item_count">
                <a-radio :value="2">2</a-radio>
                <a-radio :value="4">4</a-radio>
              </a-radio-group>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="input-wrap">
              <label>{{ t('sort.sort') }}</label>
              <a-select
                ref="select"
                v-model:value="state.sort_key"
                style="width: 100%"
              >
                <a-select-option value="DEFAULT">{{
                    t('sort.default')
                  }}
                </a-select-option>
                <a-select-option value="CREATED_AT_DESC">{{
                    t('sort.backToOld')
                  }}
                </a-select-option>
                <a-select-option value="PRICE_ASC">{{
                    t('sort.increasedPrice')
                  }}
                </a-select-option>
                <a-select-option value="PRICE_DESC">{{
                    t('sort.decreasingPrice')
                  }}
                </a-select-option>
                <a-select-option value="TITLE_DESC">{{
                    t('sort.alphabeticalAZ')
                  }}
                </a-select-option>
                <a-select-option value="TITLE_ASC">{{
                    t('sort.alphabeticalZA')
                  }}
                </a-select-option>
              </a-select>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="input-wrap">
              <a class="btn btn-danger" @click="cancel">{{ t('cancel') }}</a>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="input-wrap">
              <a
                :disabled="isFormInvalid"
                @click="onSubmit"
                class="btn btn-success"
              >{{ t('create.create') }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, onMounted, reactive, ref } from 'vue'
import MySwitch from '../../MySwitch'
import TagsInput from '../../TagsInput'
import useGlobal from '../../../composables/useGlobal'
import { required, requiredIf } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import AddLayout1Request from '../../../service/RestApi/ShowcaseRequest/Add/AddLayout1Request'

export default {
  components: { TagsInput, MySwitch },
  props: {
    collections: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { router, restApi, t, successNotify, errorNotify, emitter } =
      useGlobal()
    const collections = ref(props.collections)

    const products = ref([])

    const state = reactive({
      type: 'LAYOUT1',
      header_right_type: 'SHOPIFY_COLLECTION',
      header_title: '',
      header_visible: true,
      header_right_title: '',
      header_right_visible: true,
      header_right_shopify_collection_id: '',
      shopify_collection_id: '',
      shopify_product_ids: [],
      item_count: 4,
      sort_key: 'DEFAULT',
      source_type: 'SHOPIFY_COLLECTION',
    })

    // Required checkers
    const isHeaderTitleRequired = computed(() => {
      return ! $v.value.header_title[0].$response
    })

    const isHeaderRightTitleRequired = computed(() => {
      return ! $v.value.header_right_title[0].$response
    })

    const isHeaderRightShopifyCollectionIdRequired = computed(() => {
      return ! $v.value.header_right_shopify_collection_id[0].$response
    })

    const isShopifyCollectionIdRequired = computed(() => {
      return ! $v.value.shopify_collection_id[0].$response
    })

    const isShopifyProductIdsRequired = computed(() => {
      return ! $v.value.shopify_product_ids[0].$response
    })

    // Invalid chekers

    const isFormInvalid = computed(() => {
      return $v.value.$invalid
    })

    const isHeaderTitleInvalid = computed(() => {
      return $v.value.header_title.$error
    })

    const isHeaderRightTitleInvalid = computed(() => {
      return $v.value.header_right_title.$error
    })

    const isHeaderRightShopifyCollectionIdInvalid = computed(() => {
      return $v.value.header_right_shopify_collection_id.$error
    })

    const rules = {
      header_title: [requiredIf(() => state.header_visible)],
      header_right_title: [requiredIf(() => state.header_right_visible && state.header_visible)],
      header_right_shopify_collection_id: [requiredIf(() => state.header_right_visible && state.header_visible)],
      shopify_collection_id: [requiredIf(() => state.source_type === 'SHOPIFY_COLLECTION')],
      shopify_product_ids: [requiredIf(() => state.source_type === 'SHOPIFY_PRODUCTS')],
    }

    const $v = useVuelidate(rules, state, { $autoDirty: true })

    const cancel = () => {
      router.replace({ name: 'HomePage.Allshowcases.Page' })
    }

    const onSubmit = () => {
      const addLayout1Request = new AddLayout1Request(
        'LAYOUT1',
        state.header_title,
        state.header_visible,
        state.header_right_title,
        state.header_right_visible,
        state.header_right_shopify_collection_id,
      ).setBody(
        state.sort_key,
        state.source_type,
        state.item_count,
        state.shopify_collection_id,
        state.shopify_product_ids,
      ).toParams()

      restApi.showcaseAdd(addLayout1Request).then((response) => {

        successNotify(t('showcaseCreated'), t('showcaseCreatedDescription'))

        emitter.emit('showcase-item-added', response.getId())

      }).catch((error) => {

        errorNotify(t('showcaseNotCreated'), t('showcaseNotCreatedDescription') + error)

      })
    }

    onMounted(() => {
      restApi.fetchProducts().then((results) => {
        products.value = results.response.items
      }).catch(() => {
        errorNotify(t('productsNotFetched'), t('productsNotFetchedDescription'))
      })
    })

    return {
      router,
      products,
      t,
      collections,
      cancel,
      onSubmit,
      state,
      isFormInvalid,
      isHeaderTitleInvalid,
      isHeaderRightTitleInvalid,
      isHeaderRightShopifyCollectionIdInvalid,

      isHeaderTitleRequired,
      isHeaderRightTitleRequired,
      isHeaderRightShopifyCollectionIdRequired,
      isShopifyCollectionIdRequired,
      isShopifyProductIdsRequired,

    }
  },
}
</script>
